<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("string.mail") }} # {{ $route.params.id }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div class="pa-2">
            <div class="list-card">
              <div
                class="d-flex justify-space-between px-3 py-1"
                style="background-color: #d8c0a0"
              >
                <div class="text-h6 text-brd-md white--text">
                  {{ item.Mail.title }}
                </div>

                <div
                  class="brown--text text--lighten-2 mx-2 mt-2 text-caption"
                  v-if="
                    $moment(item.Mail.timestampExpired).diff(
                      $moment(),
                      'days'
                    ) >= 0
                  "
                >
                  {{ $t("string.expires_in") }}
                  {{
                    $moment(item.Mail.timestampExpired).diff($moment(), "days")
                  }}
                  D
                </div>
                <div class="brown--text text--lighten-2 mt-2" v-else>
                  {{ $t("string.expired") }}
                </div>
              </div>
              <div class="brown--text pa-3">
                {{ item.Mail.description }}
              </div>
              <div class="brown--text pa-3 text-center" v-if="item.Reward && item.Reward.length > 0">Rewards</div>
              <div class="d-flex align-center justify-center flex-wrap" v-if="item.Reward && item.Reward.length > 0">
                <div
                  v-for="reward in item.Reward"
                  :key="reward.id"
                  style="width: 100px"
                  class="ma-2"
                >
                  <Item
                    :imageUrl="reward.Item.images.icon.url"
                    :isShowQuantity="true"
                    :quantity="reward.quantity"
                  />
                </div>
              </div>
              <div class="d-flex align-center mx-2 mt-10 mb-5 justify-center" v-if="item.Reward && item.Reward.length > 0">
                <v-btn
                  large
                  color="yellow"
                  :disabled="item.Mail.timestampClaimed"
                  @click="claim()"
                >
                  {{ $t("action.claim") }}
                </v-btn>
              </div>
            </div>
          </div>
          <RewardDialog
              v-if="claimReward"
              :callbackClose="exitRwd"
              :showDialog="true"
              :rewards="item.Reward"
            />
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    Item: () =>
      import(
        /* webpackChunkName: "component-shop-item" */ "@/components/shop/Item.vue"
      ),
      RewardDialog: () =>
      import(
        /* webpackChunkName: "component-reward" */ "@/components/games/RewardDialog"
      ),
  },
  computed: mapState({
  }),
  props: [
    //
  ],
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },

    item: {},
    claimReward:false
  }),
  created() {
    this.api.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/mail/open";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.item = resp;
    };
  },
  mounted() {
    this.api.params = {
      mailId: this.$route.params.id,
    };
    this.$api.fetch(this.api);
  },
  methods: {
    exit() {
      this.$router.push({
        name: "PageExploreMail",
      });
    },
    claim() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/mail/claim";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        // this.item = resp;
        this.item.Mail.timestampClaimed = resp.timestampClaimed;
        this.claimReward = true
      };
      this.api.params = {
        mailId: this.$route.params.id,
      };
      this.$api.fetch(this.api);
    },
    exitRwd() {
      this.claimReward = false;
    },
  },
};
</script>

    <style scoped>
.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}
.text-stroke-sm {
  color: #ffdd1f;
  text-shadow: #000 3px 0px 0px, #000 2.83487px 0.981584px 0px,
    #000 2.35766px 1.85511px 0px, #000 1.62091px 2.52441px 0px,
    #000 0.705713px 2.91581px 0px, #000 -0.287171px 2.98622px 0px,
    #000 -1.24844px 2.72789px 0px, #000 -2.07227px 2.16926px 0px,
    #000 -2.66798px 1.37182px 0px, #000 -2.96998px 0.42336px 0px,
    #000 -2.94502px -0.571704px 0px, #000 -2.59586px -1.50383px 0px,
    #000 -1.96093px -2.27041px 0px, #000 -1.11013px -2.78704px 0px,
    #000 -0.137119px -2.99686px 0px, #000 0.850987px -2.87677px 0px,
    #000 1.74541px -2.43999px 0px, #000 2.44769px -1.73459px 0px,
    #000 2.88051px -0.838247px 0px;
}

.text-brd-md {
  text-shadow: #b89469 3px 0px 0px, #b89469 2.83487px 0.981584px 0px,
    #b89469 2.35766px 1.85511px 0px, #b89469 1.62091px 2.52441px 0px,
    #b89469 0.705713px 2.91581px 0px, #b89469 -0.287171px 2.98622px 0px,
    #b89469 -1.24844px 2.72789px 0px, #b89469 -2.07227px 2.16926px 0px,
    #b89469 -2.66798px 1.37182px 0px, #b89469 -2.96998px 0.42336px 0px,
    #b89469 -2.94502px -0.571704px 0px, #b89469 -2.59586px -1.50383px 0px,
    #b89469 -1.96093px -2.27041px 0px, #b89469 -1.11013px -2.78704px 0px,
    #b89469 -0.137119px -2.99686px 0px, #b89469 0.850987px -2.87677px 0px,
    #b89469 1.74541px -2.43999px 0px, #b89469 2.44769px -1.73459px 0px,
    #b89469 2.88051px -0.838247px 0px;
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}

.main-body {
  background-color: #f5dfc5;
}

.list-card {
  padding: 10px;
  border: #d8c0a0 4px solid;
  border-radius: 5px;
  background-color: #fff2e2;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3) !important;
  margin-bottom: 20px;
}

.coin-box {
  line-height: 12px;
  background-color: #fff;
  border: #292218 solid 2px;
  margin: 0 3px;
  font-size: x-small;
  text-align: center;
}

.coin-img {
  background-color: #6b583c;
}
</style>